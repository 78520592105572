import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, Typography} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CustomDropZone from "../../School/CustomDropZone";
import ServiceCategoryForm from "../../School/ServiceCategoryForm";
import {SuccessAlert} from "../../snackBar Alerts/successAlert";
import {useDispatch, useSelector} from "react-redux";
import CustomTable from "../../School/CustomTable";
import {
    addCategoriesToAdd,
    clearCategoriesToAdd,
    removeFromCategoriesToAdd,
    updateCheckedItems
} from "../../../features/businessCategorySlice";
import HttpComponent from "../../School/MakeRequest";
import {changeToken} from "../../../features/userSlice";
import {ErrorAlert} from "../../snackBar Alerts/errorAlert";
import ReusableModal from "../../School/ReusableModal";
import warningImage from "../../School/Images/warning-remove-icn.svg"
import EditServiceCategoryForm from './EditServiceCategory';

const GeneralBusinessServiceCategory = (props) =>{
    const { next } = props;
  const [formView, setFormView] = useState("form"); // return this to inital
    const { categoriesToAdd, checkedItems} = useSelector((store) => store.businessCategory);
    const [indexToBeDeleted, setIndexToBeDeleted] = useState();
    const dispatch = useDispatch();
    const [warningOpen, setWarningOpen] = useState(false);
    const [editItemId, setEditItemId] = useState(null);
    const columns = [
        { headerName: "Category Name", field: "name", flex:3 },
        { headerName: "Category Type", field: "type", flex:3 },
        { headerName: "Category Description", field: "description" ,flex:3},
        {
            field: 'Action',
            headerName: 'Action',
            renderCell: (params) => (
                <Box>
                    
                <Button
                    sx={{
                        color:"#dc3545",
                        '&:hover': {
                            color: '#dc3545'
                        }
                    }}

                    onClick={()=>{
                        setFormView("edit")
                        console.log(categoriesToAdd);
                    }}
                >
                    <EditIcon color="primary"/>
                </Button>
                <Button
                    sx={{
                        color:"#dc3545",
                        '&:hover': {
                            color: '#dc3545'
                        }
                    }}

                    onClick={() => {
                        const index = categoriesToAdd.findIndex((category) => category.name === params.row.name);
                        setIndexToBeDeleted(index)
                        setWarningOpen(true);
                    }}
                >
                    <DeleteIcon color="danger" />
                </Button>
                </Box>
            ),
            flex: 1
        },
    ];

    const handleWarningClose = () =>{
        setWarningOpen(false);
    }

    const doRemove = () =>{
        dispatch(removeFromCategoriesToAdd({index:indexToBeDeleted}))
        handleWarningClose()
        setSuccessToast({state: true, message:"Category removed successfully."})
    }

    const createButton = {
        "width": "15.313rem",
        "height": "2.813rem",
        "borderRadius": "5px",
        "border": "solid 1px #002543",
        "backgroundColor": "#fff",
        "color":"#032541",
        '&:hover': {
            backgroundColor: '#fff',
            color: '#032541'
        }
    }

    const buttonStyles =
        {"minWidth":"7.813rem",
            "height":"2.813rem",
            "borderRadius":"4px",
        };
    const [successToast, setSuccessToast] = useState({state: false, message: ""})
    const [errorShow, setErrorShow] = useState({state: false, message: ""})
    const [tableData, setTableData] = useState([])
    const { userId ,X_Authorization } = useSelector((store) => store.user);
    const [fromUpload, setFromUpload] = useState(false);

    const showUploaded = (newCategories) => {
        let data = newCategories.newRecords
        setSuccessToast({state: true, message:"Service categories uploaded successfully."})
        for(let i =0; i < data.length; i++){
            let newData ={
                name:data[i].categoryName,
                description:data[i].categoryDescription,
                productService:data[i].productService
            }
            dispatch(addCategoriesToAdd(newData))
        }
        setFromUpload(true)
        setFormView("Done")
    }


    const submit = () =>{
        HttpComponent({
            method: 'POST',
            url: '/api/add_categories',
            body: {categories:categoriesToAdd},
            token: localStorage.getItem('X-Authorization')
        }).then((data)=>{
            console.log("here store is data",data);
            if(data.status === 201){
                props.next()
                dispatch(clearCategoriesToAdd())
            }else{
                console.error("Error setting info")
                setErrorShow({state:true, message:data.response.message})
            }
        }).catch((error)=>{
            console.error(error.message);
        })
    }

    const uploadNext = () =>{
        props.next()
        dispatch(clearCategoriesToAdd())
    }

    useEffect(() => {
        if(!categoriesToAdd){
            setSuccessToast({state:true, message: "Business details saved successfully."})
        }else{
            setFormView("done")
        }
    }, []);

    useEffect(() => {
        const dataWithGridId = categoriesToAdd?.map((row, index) => ({
            ...row,
            gridId: `${index}-${row.id}`
        }));
        setTableData(dataWithGridId)
    }, [categoriesToAdd]);
  return(
    <Box component="div" sx={{display:"flex", justifyContent:"center", margin:2, width:"100%"}}>
            <Box component="div" sx={{display:"flex", flexDirection:"column", width:"100%"}}>
                <SuccessAlert  vertical="top"
                               horizontal="right"
                               onClose={()=>setSuccessToast({...successToast, state:false})}
                               open={successToast.state}
                               message={successToast.message} />
                <ErrorAlert
                    vertical="top"
                    horizontal="right"
                    onClose={()=>setErrorShow({...errorShow, state:false})}
                    open={errorShow.state}
                    message={errorShow.message}/>
                {formView === "initial" ?
                    //    default view
                    <>
                        <Box sx={{alignSelf:"center", marginY:2}}>
                            <Typography sx={{fontSize:"1rem", color:"#707070"}}>
                                Add at least 1 service category
                            </Typography>
                        </Box>

                        <Box sx={{alignSelf:"center"}}>
                            <CustomDropZone
                                setShowErrorToast={setErrorShow}
                                setShowSuccessToast={setSuccessToast}
                                url={"/api/upload_product_category"}
                                successAction={showUploaded}
                            />

                        </Box>


                        <Box component="div" sx={{alignSelf:"center", marginY:2}}>
                            <Button sx={createButton} onClick={()=>setFormView("form")}>
                                Create Category
                            </Button>
                        </Box>

                    </>
                    : formView === "form" ?
                    //    form view here
                    <>
                        <Box sx={{alignSelf:"center", marginY:2}}>
                            <Box sx={{marginY:2, textAlign:"center",}}>
                                <Typography sx={{fontSize:"1rem", color:"#707070"}}>
                                    Create a Service Category
                                </Typography>
                            </Box>

                            <ServiceCategoryForm setView={setFormView} next={next} /> 
                            
                        </Box>


                    </>
                    : formView === "edit" ?
                    //    edit form view here
                    <>
                        <Box sx={{alignSelf:"center", marginY:2}}>
                            <Box sx={{marginY:2, textAlign:"center",}}>
                                <Typography sx={{fontSize:"1rem", color:"#707070"}}>
                                Edit Service Category
                                </Typography>
                            </Box>

                            <EditServiceCategoryForm setView={setFormView} next={next}/>
                        </Box>


                    </>
                    :
                        <>
                   {/*    custom data grid will go*/}
                       <Box component={"div"} sx={{display:"flex", width:"100%", justifyContent:"space-between", marginTop:"2%"}}>
                           <Box component="div" sx={{marginLeft:"5.8%"}}>
                               <Typography sx={{fontSize:"1rem", color:"#707070"}}>
                                   Product/Service Categories
                               </Typography>
                           </Box>
                           <Box sx={{display:"flex", marginRight:"5%"}}>
                               <Button sx={{
                                  ...buttonStyles,
                                  border:" solid 1px #002543",
                                  color:"#002543",
                                 '&:hover': {
                                     backgroundColor:"transparent",
                                     color: '#002543',
                                 }
                                }}
                                     onClick={()=>setFormView("initial")}>
                                        Upload File
                               </Button>

                               <Button sx={{
                                   ...buttonStyles,
                                   marginLeft:1,
                                   backgroundColor:" #032541",
                                   color:"white",
                                   '&:hover': {
                                       backgroundColor: '#032541',
                                       color: 'white'
                                   }
                               }}
                                       onClick={()=>setFormView("form")}
                               >
                                   Add Category
                               </Button>
                           </Box>

                       </Box>
                        <Box sx={{alignSelf:"center", width:"90%", marginTop:"2%"}}>
                            <CustomTable data={tableData} columns={columns} rowsPerPage={5} checkboxSelection disableSelectionOnClick />
                        </Box>
                   <ReusableModal open={warningOpen} onClose={handleWarningClose} width="34.688rem">
                       <Box sx={{display:"flex", justifyContent:"center", padding:"3.125rem"}}>
                           <Box sx={{alignSelf:"center",display:"flex", flexDirection:"column", justifyContent:"center"}}>
                               <Box sx={{display:"flex"}}>
                                   <img style={{paddingRight:"2.5rem"}} src={warningImage} alt="Warning"/>
                                   <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                                       <Typography sx={{color:"#032541", fontWeight:600}}>
                                           Delete Service Category?
                                       </Typography>
                                       <Typography sx={{color:"#707070", fontSize:"14px", marginTop: '9px'}}>
                                           Service category will be deleted. <br/> This action cannot be undone.
                                       </Typography>
                                   </Box>
                               </Box>

                               <Box sx={{display:"flex", justifyContent:"center", gap:2, marginTop:5}}>
                                   <Button sx={{
                                       backgroundColor:"transparent",
                                       border:"1px solid #002543",
                                       color:"#002543",
                                       width:"7.813rem",
                                       height:"2.813rem",
                                       '&:hover': {
                                           backgroundColor:"transparent",
                                           color:"#002543",
                                       }
                                   }}
                                   onClick={handleWarningClose}
                                   >
                                       Cancel
                                   </Button>
                                   <Button sx={{
                                       backgroundColor:"#dc3545",
                                       color:"#ffffff",
                                       width:"7.813rem",
                                       height:"2.813rem",
                                       '&:hover': {
                                           backgroundColor:"#dc3545",
                                           color:"#ffffff",
                                       }
                                   }}
                                   onClick={doRemove}
                                   >
                                       Remove
                                   </Button>
                               </Box>
                           </Box>
                       </Box>
                   </ReusableModal>
                            {!fromUpload ?
                                <Box component="div" sx={{display:"flex", width:"95%", justifyContent:"end"}}>
                                    <Button sx={{
                                            "width":"7.813rem",
                                            "height":"2.813rem",
                                            backgroundColor:"#17ae7b",
                                            color:"white",
                                            '&:hover': {
                                                backgroundColor: '#17ae7b',
                                                color:"white",
                                            }
                                        }}
                                                onClick={submit}
                                        >
                                            Next
                                        </Button>
                                        
                                            {/* <Button sx={{
                                                "width":"7.813rem",
                                                "height":"2.813rem",
                                                backgroundColor:"#dc3545",
                                                borderRadius: '4px',
                                                color:"white",
                                                '&:hover': {
                                                    backgroundColor: '#dc3545',
                                                    color:"white",
                                                }
                                            }}
                                                    // onClick={submit}
                                            >
                                                Remove
                                            </Button> */}
                                </Box>
                                :
                                <Box component="div" sx={{display:"flex", width:"95%", justifyContent:"end"}}>
                                    <Button sx={{
                                        "width":"7.813rem",
                                        "height":"2.813rem",
                                        backgroundColor:"#17ae7b",
                                        color:"white",
                                        '&:hover': {
                                            backgroundColor: '#17ae7b',
                                            color:"white",
                                        }
                                    }}
                                            onClick={uploadNext}
                                    >
                                        Next
                                    </Button>
                                </Box>
                            }

                        </>
                }
            </Box>
        </Box>
  )
}

export default GeneralBusinessServiceCategory;